import type {
  GalleryData,
  GIFData,
  ImageData,
  Media,
  VideoData,
} from 'ricos-schema';
import type { DraftContent } from 'ricos-content';
import { getAbsoluteUrl } from 'wix-rich-content-plugin-gallery/libs/baseUrlConverter';
import { fromDraft } from 'ricos-content/libs/converters';
import { getPluginData, getText } from 'ricos-content/libs/content-application';
import { Node_Type, safeJsonParse } from 'wix-rich-content-common';

export interface IItemContent {
  text?: string;
  gifs: GIFData[];
  images: ImageData[];
  videos: VideoData[];
  galleries: GalleryData[];
}

export function extractContent(contentJSON: string): IItemContent {
  const content = safeJsonParse(contentJSON) as DraftContent;

  if (!content) {
    return {
      text: undefined,
      gifs: [],
      images: [],
      videos: [],
      galleries: [],
    };
  }

  return {
    text: getText(fromDraft(content)).join(' '),
    images: prepareImages(content),
    videos: prepareVideos(content),
    galleries: prepareGalleries(content),
    gifs: getPluginData(fromDraft(content), 'GIF' as Node_Type),
  };
}

function prepareImages(content: DraftContent): ImageData[] {
  const images: ImageData[] = getPluginData(
    fromDraft(content),
    'IMAGE' as Node_Type,
  );

  return images.map((image) => ({
    ...image,
    image: prepareMediaItem(image.image),
  }));
}

function prepareGalleries(content: DraftContent): GalleryData[] {
  const galleries: GalleryData[] = getPluginData(
    fromDraft(content),
    'GALLERY' as Node_Type,
  );

  return galleries.map((gallery) => ({
    ...gallery,
    items: gallery.items.map((item) => ({
      ...item,
      image: !item.image
        ? undefined
        : {
            ...item.image,
            media: prepareMediaItem(item.image.media),
          },
      video: !item.video
        ? undefined
        : {
            ...item.video,
            media: prepareMediaItem(item.video.media),
            thumbnail: prepareMediaItem(item.video.thumbnail),
          },
    })),
  }));
}

function prepareVideos(content: DraftContent): VideoData[] {
  const videos: VideoData[] = getPluginData(
    fromDraft(content),
    'VIDEO' as Node_Type,
  );

  return videos.map((video) => ({
    ...video,
    thumbnail: prepareMediaItem(video.thumbnail),
    video: prepareMediaItem(video.video),
  }));
}

function prepareMediaItem(media?: Media): Media | undefined {
  if (!media) {
    return undefined;
  }

  const id = media.src?.id || media.src?.url;
  const isVideo = media.duration !== undefined;

  if (!id) {
    return media;
  }

  return {
    ...media,
    src: {
      ...media.src,
      url: getAbsoluteUrl(id, isVideo ? 'video' : 'image'),
    },
  };
}
